const wellLife = ['1002', '1009', '2001', '2002', '2003', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2019', '1013', '2023', '2028', '2031', '2032', '2034', '2035', '2037', '2040', '4444'];
const elite = ['1004', '1005', '1006', '1007', '1008', '1009', '1010', '1011', '1012', '1014', '1015', '1016', '1017', '1018', '2001', '2002', '2004', '2005', '2006', '2007', '2011', '2013', '2014', '2018', '2019', '2020', '2021', '2022','2024', '2025', '2027', '2029', '2030', '2033', '2036', '2038', '2042'];
const advantage = ['1008', '1015', '1016', '1017', '1018', '2004', '2005', '2006', '2007', '2011', '2012', '2018', '2019', '2022', '2023', '2024', '2025', '2027', '2029', '2030', '2031', '2033', '2036', '2038', '2042'];
const pureCat = ['2041', '4444'];

const employers = ['1004', '1005', '1006', '1007', '1008', '1010', '1011', '1012', '1013', '1014', '1015', '1016', '1017', '1018'];
const households = ['1002', '1009', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023','2024', '2025', '2027', '2028', '2029', '2030', '2031', '2032', '2033', '2034', '2035', '2036', '2037', '2038', '2040', '2041', '2042', '4444'];


export const isWellLife = (clientId) => {
    if (wellLife.includes(clientId)) return true
    else return false
}

export const isElite = (clientId) => {
    if (elite.includes(clientId)) return true
    else return false
}

export const isAdvantage = (clientId) => {
    if (advantage.includes(clientId)) return true
    else return false
}

export const isPureCat = (clientId) => {
    if (pureCat.includes(clientId)) return true
    else return false
}

export const isEmployer = (id) => {
    if (employers.includes(id)) return true
    else return false
}